import { useStaticQuery, graphql } from "gatsby"
import { flattenNode } from "services/arrays"

export const useTrainingMaterials = () => {
  let trainingMaterials = useStaticQuery(graphql`
    query AirtableAllTrainingMaterials {
      allAirtableallTrainingMaterials(
        filter: {
          data: { Type: { eq: "Training Material" }, Status: { eq: "Active" } }
        }
        sort: { fields: data___Order }
      ) {
        nodes {
          data {
            Name
            Last_Modified
            Role_Keys
            Link
            Status
          }
        }
      }
    }
  `)
  return flattenNode(trainingMaterials?.allAirtableallTrainingMaterials)
}
