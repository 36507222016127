import React, { Fragment } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons"

import MenuItem from "./MenuItem"
import { useUserPermissions } from "../../Auth/hooks/useUserPermissions"

import { getSignedInUser } from "../../Auth/services/user"
import { handleSignOut } from "../../Auth/services/signout"

import { menu } from "../utils/menu"

import styles from "../utils/layout.module.scss"

const Menu = () => {
  const data = useStaticQuery(
    graphql`
      {
        logo: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
          childImageSharp {
            fixed(height: 27) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `
  )
  let logo = data.logo.childImageSharp.fixed
  const permissions = useUserPermissions()
  const { userData, activeMedstaff } = getSignedInUser()

  const renderMenuItem = ({ link }) => {
    let permission = permissions?.find(
      (permission) => permission?.key === userData?.role
    )

    let hasPermission = false
    for (let path of permission?.allowedPaths || []) {
      if (path.includes(link.link)) {
        hasPermission = true
      }
    }

    switch (true) {
      case userData?.requireDeputy && activeMedstaff && hasPermission:
      case userData?.requireDeputy && link.label === "Deputy":
      case !userData?.requireDeputy && hasPermission:
        return <MenuItem link={link} key={link.link} />

      default:
        return null
    }
  }
  return (
    <div className={classNames("is-hidden-touch", styles["sideNavigation"])}>
      <div
        className={classNames(
          "is-flex is-flex-direction-column is-justify-content-space-between",
          styles["sideNavigation__sideNavContainer"]
        )}
      >
        <section>
          <div
            className={classNames(
              "mb-2 is-flex has-text-centered",
              styles["sideNavigation__logoContainer"]
            )}
          >
            <Link to="/">
              <Link to="/">
                <Img loading="eager" fixed={logo} />
              </Link>
            </Link>
          </div>
          <aside className="menu">
            {menu.map((section) => (
              <Fragment>
                <ul className="menu-list">
                  {section?.links?.map((link) => {
                    return renderMenuItem({ link })
                  })}
                </ul>
              </Fragment>
            ))}
            <div className={styles[`logout`]}>
              <Link
                className={classNames(
                  " menu-link",
                  styles["sideNavigation__linkContainer"]
                )}
                to="/sign-in"
              >
                <div
                  className={classNames(styles["sideNavigation__link"])}
                  onClick={handleSignOut}
                  role="button"
                  aria-hidden="true"
                >
                  <span
                    className={classNames(
                      "is-inline-block ml-1 mr-1 ",
                      styles["sideNavigation__menuIcon"]
                    )}
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </span>
                  <span className={classNames("")}>{"Log out"}</span>
                </div>
              </Link>
            </div>
          </aside>
        </section>
      </div>
    </div>
  )
}

export default Menu
