import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import Img from "gatsby-image"

import { isBrowser } from "services/general"

import styles from "../utils/layout.module.scss"

const MenuItem = ({ link }) => {
  let url = ""
  // const [userData, setUserData] = useState({})
  if (isBrowser()) url = window.location.pathname

  return (
    <li key={link.link}>
      <Link
        className={classNames({
          "is-active": url
            .replaceAll("/", "")
            .includes(link.link.replaceAll("/", "")),
        })}
        to={link.link}
      >
        <div className={classNames(styles["sideNavigation__link"], {})}>
          <span
            className={classNames(
              "is-inline-block ml-1 mr-1 has-text-black ",
              {
                "has-text-white": url
                  .replaceAll("/", "")
                  .includes(link.link.replaceAll("/", "")),
              },
              styles["sideNavigation__menuIcon"]
            )}
          >
            {typeof link["icon"] === "string" ? (
              <img
                className={classNames(styles["mgLogo"], {
                  "is-active": url
                    .replaceAll("/", "")
                    .includes(link.link.replaceAll("/", "")),
                })}
                src="/images/logos/medgrocer-square.png"
                alt="mg-logo"
              />
            ) : (
              <FontAwesomeIcon icon={link.icon} />
            )}
          </span>
          <span
            className={classNames({
              "has-text-white": url
                .replaceAll("/", "")
                .includes(link.link.replaceAll("/", "")),
            })}
          >
            {link.label}
          </span>
        </div>
      </Link>
    </li>
  )
}

export default MenuItem
