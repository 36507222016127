import React, { Fragment } from "react"
import { Link, navigate } from "gatsby"
import classNames from "classnames"

import SEO from "./SEO"
import Menu from "./Menu/index.js"
import Navbar from "./Navbar"
import PrivateRoute from "./PrivateRoute"
import Container from "layout/Container"
import Button from "elements/Button"

import styles from "./utils/layout.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

// import { getContextFromSession } from "services/context"

const DashboardLayout = ({
  children,
  seoTitle,
  background,
  isPrivate,
  title,
  titleConfig,
  subtitle,
  backPathUrl,
  buttons = null,
  links = null,
}) => {
  // useEffect(() => {
  //   if (
  //     getSignedInUser().userData.requireDeputy &&
  //     pageContext.requireActiveMedstaff &&
  //     !getSignedInUser().activeMedstaff
  //   ) {
  //     navigate("/deputy/employees")
  //   }
  // })

  return (
    <body>
      <SEO title={seoTitle} />
      <div
        className={classNames(`is-background-${background || "light-grey"}`)}
      >
        <PrivateRoute isPrivate={isPrivate || true}>
          <Menu />
          <main className={classNames(styles["dashboardContainer"])}>
            <Navbar color="white" />

            <Container
              isCentered={true}
              className="mt-5"
              fullhd={12}
              desktop={12}
            >
              {title ? (
                <section
                  className={classNames(
                    // "header mx-1 mt-4 is-align-items-center px-1",
                    // styles["dashboardHeader"],
                    "header px-1",
                    styles["dashboardHeader"],
                    // { "is-justify-content-center": !links },
                    { "is-justify-content-space-between": links },
                    { "is-justify-content-space-between": buttons }
                  )}
                >
                  <Fragment>
                    <h2
                      className={classNames(
                        "m-0 has-text-primary mr-1 is-size-3-mobile",
                        styles["dashboardTitle"]
                      )}
                    >
                      {backPathUrl ? (
                        <Link to={backPathUrl} className="mr-2">
                          <FontAwesomeIcon icon={faChevronLeft} size="xs" />
                        </Link>
                      ) : null}
                      <span //eslint-disable-line
                        className={classNames(styles[titleConfig?.styling])}
                        onClick={() => navigate(titleConfig?.link)}
                        onKeyDown={() => navigate(titleConfig?.link)}
                      >
                        {title}
                      </span>
                      {subtitle ? (
                        <span className="subtitle">
                          <br />
                          {subtitle}
                        </span>
                      ) : null}
                    </h2>
                  </Fragment>
                  {links ? (
                    <div className="buttons is-flex-wrap-nowrap mt-2-mobile">
                      {links.map((link) => (
                        <Link
                          to={!link?.disabled ? link?.to : null}
                          disabled={link?.disabled}
                          className={`button is-disabled is-${
                            link?.color || "primary"
                          }`}
                          state={link?.state}
                        >
                          {link?.label}
                        </Link>
                      ))}
                    </div>
                  ) : null}
                  {buttons ? (
                    <div className="buttons is-flex-wrap-nowrap mt-2-mobile">
                      {buttons.map((button) => (
                        <Button
                          label={button.label}
                          onClick={button.onClick}
                          color="primary"
                          isDisabled={button.isDisabled}
                        >
                          {button.label}
                        </Button>
                      ))}
                    </div>
                  ) : null}
                </section>
              ) : null}
              <div className="p-1-desktop p-1-tablet p-0-mobile ">
                {children}
              </div>
            </Container>
          </main>
        </PrivateRoute>
      </div>
    </body>
  )
}

export default DashboardLayout
