import {
  faCalendar,
  faBox,
  faUser,
  faStethoscope,
  faHandsHelping,
  faFile,
  faUserCheck,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons"

export const menu = [
  {
    section: "General",
    links: [
      {
        label: "Dashboard",
        link: "/dashboard",
        icon: faUser,
        requireDeputy: true,
      },
      {
        label: "Patients",
        link: "/patients",
        icon: faStethoscope,
        requireDeputy: true,
      },
      {
        label: "Consultations",
        link: "/consultations",
        icon: faCalendar,
        requireDeputy: true,
      },
      {
        label: "Schedule",
        link: "/schedule",
        icon: faCalendar,
        requireDeputy: true,
      },
      {
        label: "Document Validation",
        link: "/document-validation",
        icon: faUserCheck,
        requireDeputy: true,
      },
      {
        label: "Inventory",
        link: "/inventory",
        icon: faBox,
        requireDeputy: true,
      },
      {
        label: "AMR",
        link: "/amr",
        icon: faChartBar,
        requireDeputy: true,
      },
      {
        label: "Endorsement",
        link: "/endorsement",
        icon: faFile,
        requireDeputy: true,
      },
      {
        label: "Online Helpdesk",
        link: "/online-helpdesk",
        icon: faHandsHelping,
        requireDeputy: true,
      },
      {
        label: "Deputy",
        link: "/deputy/employees",
        icon: faUser,
        requireDeputy: true,
      },
      {
        label: "MedGrocer HR",
        link: "/medgrocer-hr",
        icon: "medgrocer",
        requireDeputy: true,
      },

      // {
      //   label: "Vaccinees",
      //   link: "/vaccinees",
      //   icon: faUser,
      // },
    ],
  },
]
