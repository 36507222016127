import React from "react"
import { navigate } from "gatsby"
import moment from "moment"

import DashboardLayout from "../../Layout/DashboardLayout"
import Card from "elements/Card"
import Message from "elements/Message"

import { useTrainingMaterials } from "../hooks/useAirtableTrainingMaterials"
import { getSignedInUser } from "../../Auth/services/user"

import { GATSBY_MEDSTAFF_ROLE_ID } from "gatsby-env-variables"

const TrainingMaterials = ({ pageContext }) => {
  const trainingMaterials = useTrainingMaterials()
  let roleId = getSignedInUser()?.permission?.key || GATSBY_MEDSTAFF_ROLE_ID
  return (
    <DashboardLayout pageContext={pageContext} title="Training Materials">
      <hr />
      <div className="is-gapless">
        {trainingMaterials.map((material) => {
          let roles = material.roleKeys
          if (roles.includes(roleId)) {
            return (
              <div
                aria-hidden="true"
                onClick={() => {
                  navigate(material.link)
                }}
                role="button"
              >
                <Card className="my-1">
                  <h4 className="has-text-primary">{material.name}</h4>
                  <p className="has-text-primary">
                    Last modified:{" "}
                    {moment(material.lastModified).format("YYYY-MM-DD")}{" "}
                  </p>
                </Card>
              </div>
            )
          } else return null
        })}
        {!trainingMaterials?.length ? (
          <Message color="warning">No training materials found.</Message>
        ) : null}
      </div>
    </DashboardLayout>
  )
}
export default TrainingMaterials
